import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import UniHeader from '../components/uniHeader';

const TermsofUse = () => {
    return (
        <Layout>
            <SEO
                title="Terms of Use | Credit Score Maestro"
                description="Credit Score Maestro terms of use. Build great credit."
                url="https://creditscoremaestro.com/termsofuse"
            />
            <UniHeader title="Credit Score Maestro Terms of Use">
            <br />
                <div className="container terms mt-5">
                <div className="text-center">
                    <h2><u>TERMS OF USE AGREEMENT<br />Last Updated: 9/1/2017</u></h2>
                </div>
                <br />
                <h3>TERMS DEFINED</h3> 
                <p>Within this Agreement, distinguished terms will be defined as follows:</p>
                <ul>
                    <li>"Agreement" will refer to all conditions within the entirety of this Terms of
                    Use Agreement.</li>
                    <li>"Our", "Us", and "We" means CreditScoreMaestro.com its owners, principles, shareholders,
                    subsidiaries, or divisions.</li>
                    <li>"Service(s)" means Your use of the Website for any purpose.</li>
                    <li>"Website" means CreditScoreMaestro.com and any services it offers or provides.</li>
                    <li>"You" or "Your" means the user of CreditScoreMaestro.com and any services it offers 
                    or provides.</li>
                </ul>
                <h3>OVERVIEW</h3>
                <p>This Terms of Use Agreement (hereinafter The Agreement) sets forth the terms and conditions 
                applicable to Your use of the Website.</p>  
                <p>By using the Website for any purpose or by completing the registration process, You agree to  
                be bound by all terms of the Agreement.</p> 
                <p>You agree that by use of the Website to receive the Agreement in electronic format and dispose 
                of the requirement to receive it in any other format or to sign any documentation to be bound by 
                the Agreement.</p>
                <p>We reserve the right to modify the Agreement at any time. Any modification will become effective
                and applicable to You upon our posting the modified Agreement on the Website.</p>
                <p>Your continued use of the Website after a modified Agreement constitutes Your implied Agreement
                to be bound by any and all terms in that Agreement. You agree it is Your sole responsibility to be
                aware of the effective Agreement upon use of the Website.</p>
                <h3>ELIGIBILITY REQUIREMENTS</h3>
                <p>You agree, affirm, and represent that You are:</p>
                <ul>
                        <li>That You have the right, authority, and capacity to consent to the Agreement;</li> 
                        <li>Will use the Website in a manner consistent with any and all applicable laws and 
                        regulations;</li>
                </ul>
                <h3>USER AGREEMENT</h3>
                <p>The Agreement constitutes Your Agreement with Us with respect to Your use of the services and 
                the Website. To become or remain an authorized user of the website and its services, You must abide
                by all terms and conditions of the Agreement.</p>
                <p>Your right to use the Website and its Services are subject to any conditions, limitations, 
                or restrictions We establish, at any time at Our sole discretion.</p>
                <p>We may alter, suspend, or discontinue any aspect of the Website and its services at any time. We 
                may also impose limits on certain features and aspects of the Website and its services or restrict 
                Your access to any part or all of the Services for any reason, including breach of the Agreement 
                without liability.</p>
                <p>Without affecting the generality of the above terms, any fraudulent, abusive, or otherwise 
                illegal activity that may affect the enjoyment of the Services or the internet may be grounds for 
                immediate termination of Your access to all or part of the Service, at Our discretion, and You may be 
                referred to appropriate law enforcement agencies.</p>
                <h3>NO RESPONBILITY</h3> 
                <p>You agree that We are not responsible or liable for any interruption or suspension of the Services, 
                regardless of the cause of the interruption or suspension.</p>		
                <h3>CODE OF CONDUCT</h3>
                <p>You agree to use the Services in accordance with the following Code of Conduct:</p>	
                <ul className="termnum">
                        <li>1. You will not use the Services to engage in any form of harassment or offensive behavior, 
                        including but not limited to the posting of communications, pictures or recordings which contain 
                        libelous, slanderous, abusive, or defamatory statements, or racist, pornographic, obscene, or otherwise offensive language;</li>
                        <li>2. You will not forward chain letters through the Services;</li>
                        <li>3. You will not use the Services to infringe the privacy rights, property rights, or any other
                        rights of any person;</li>
                        <li>4. You will not post messages, pictures or recordings or use the Services in any way that;</li>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a.) violates, plagiarizes, or infringes upon the rights of any third party, including but not limited 
                        to any <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; copyright or trade-mark law, privacy, or other personal or proprietary rights, or;</li>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b.) is fraudulent or otherwise unlawful or violates any law.</li>
                        <li>5. You will not use the Services to distribute, promote, or otherwise publish any material containing
                        any	solicitation for funds, advertising or solicitation for goods or services. Parties responsible for the 
                        distribution, promotion, or publication of any material containing any solicitation for funds, advertising, 
                        or solicitation for goods or services agree to pay Credit Score Maestro One Thousand US Dollars ($1000.00) per 
                        account, per day involved with such activities;</li>
                        <li>6. You will not use the Services to distribute or upload any virus, or malicious software of any type, 
                        or do anything else that might cause harm to the Services, the Website, CreditScoreMaestro, its systems, or any 
                        other members' systems in any way;</li>
                        <li>7. You will not post or transmit in any manner any contact information including but not limited to email 
                        address, "instant messenger" nicknames, or contact information, telephone numbers, postal addresses, URLs, 
                        and full names through publicly posted information on the Website and through its Services;</li>
                        <li>8. You will not cause the Services to be accessed through any automated or robotic means, including but 
                        not limited to the rapid access of the site as in a denial-of-service attack. Such restriction shall not apply 
                        to legitimate search engine activity that does not place an unreasonable burden on the Services;</li>
                        <li>9. You will not use a third-party application such as a mobile smart phone application, social media,
                        or other Web page widget, or any other such mobile, social media, Web, or desktop application to access the 
                        Services except where such application is either provided by Us or endorsed by Us. Such restriction shall not
                        apply to a browser application which merely displays the pages of the Services in their entirety without
                        modification or reformulation of content.</li>
                </ul>	
                <h3>MONITORING OF INFORMATION</h3>
                <p>We are not responsible for the content of any public posting, message, pictures, or other
                materials. We do not and cannot review every or all accounts. We reserve the right, but have no obligation, to delete, 
                edit, or move any public posting, message, picture, or other material on the Website that We, in our sole
                discretion, deem a violation of the code of conduct, unacceptable, or any other applicable material and We are indemnified by You for any liability or other
                legal action from any profile, public posting, message, picture, or other material You may upload to the Website and its 
                Services.</p> 	
                <h3>PROPERTY INFORMATION</h3>
                <p>The Website and its Services contain information that is proprietary to Us, our partners, and Our Users. We 
                assert full copyright protection in the Website and its Services. Information posted by Us, Our partners, or 
                Our Users of the Website and its services may be protected whether or not it is identified as proprietary to Us 
                or them. You agree not to modify, copy, or distribute any such information by any means for any purpose without 
                first receiving express written permission from the owners of such information.</p>
                <h3>INTERACTIONS WITH OTHERS</h3>
                <p>YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. YOU UNDERSTAND THAT Credit Score Maestro CURRENTLY 
                DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS. CREDIT SCORE MAESTRO ALSO DOES NOT INQUIRE INTO THE BACKGROUND 
                OF ALL OF ITS MEMBERS OR ATTEMPT TO VERIFY THE STATEMENTS OF ITS MEMBERS. CREDIT SCORE MAESTRO RESERVES THE RIGHT TO CONDUCT
                ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES), AT ANY TIME AND USING 
                AVAILABLE PUBLIC RECORDS.</p>
                <p>IN NO EVENT SHALL CREDIT SCORE MAESTRO BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, 
                COMPENSATORY,CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN 
                CONNECTION WITH THE USE OF THE SERVICES, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR 
                ANY OTHER DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS WITH OTHER REGISTERED USERS OF THIS SERVICE OR PERSONS
                YOU MEET THROUGH THIS SERVICE. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL INTERACTIONS WITH OTHER MEMBERS OF 
                THE SERVICE, PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON. YOU SHOULD NOT PROVIDE YOUR FINANCIAL INFORMATION (FOR EXAMPLE, YOUR CREDIT CARD OR BANK ACCOUNT 
                INFORMATION) TO OTHER MEMBERS.</p> 		
                <h3>ARBITRATION</h3>
                <p>You agree as a condition of using the Website and its services that any dispute or legal claim You have 
                against Us will be resolved exclusively through arbitration under the laws of the State of Delaware, U.S.A., 
                and will be held in that state.</p>	
                <h3>EXTERNAL LINKS</h3>		
                <p>You understand that in utilizing any External Links You may encounter content deemed offensive, indecent, or
                objectionable and the content may or may not be identified as having explicit language; that the results of any
                search or entering of a particular URL may automatically and intentionally generate links or references
                to objectionable material.</p>		
                <p>You agree that use of any External Links is at Your sole risk and We shall not have any liability to You
                for any indecent, offensive, or objectionable content. Additionally, You agree it is Your sole and exclusive 
                obligation to preventing children or other persons from viewing indecent, offensive, or objectionable material
                on the External Links or Our Website and its services.</p>		
                <p>You agree We are not responsible in any way for Your use of the External Links, and You understand We do 
                not warrant, endorse, or assume any liability or responsibility to You or any other person for the content,
                Agreement, rights, materials, products, services of third parties, or in any other way when You utilize an
                External Link.</p>		
                <p>You agree We are not responsible or liable for the contents of, updates to, or privacy practices of third
                parties. The personal data You give to others is not covered by Our Personal Privacy Policies. If a third 
                party company shares their personal data with Us, data sharing shall be governed by the third party's privacy 
                policy.</p>		
                <p>You understand and agree that Your use of External Links may cause harmful or unwanted content or malicious 
                software infecting or interacting with Your computer or mobile device. You accept all responsibility and risk
                in connecting with such External Links. You agree We have no responsibility to You in the event Your computer
                or mobile device is affected in any way by the use of External Links.</p>
                <h3>MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</h3>
                <p>If You believe that any material or content distributed by Credit Score Maestro constitutes copyright infringement, please 
                provide Us with the following information: an electronic or physical signature of the person authorized to act on behalf
                of the owner of the copyright interest; a description of the copyrighted work that You claim has been infringed; a 
                description of where the material that You claim is infringing is located on our Website; Your address, telephone number
                and email address; a written statement by You that You have a good faith belief that the disputed use is not authorized
                by the copyright owner, its agent, or the law; a statement by You, made under penalty of perjury, that the above 
                information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's 
                behalf.</p> 
                <h3>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>  
                <p>YOU UNDERSTAND AND AGREE THAT YOU USE THE WEBSITE AND SERVICES AT YOUR OWN RISK. SERVICES ARE PROVIDED ON AN "AS IS" 
                BASIS WITHOUT REPRESENTATATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO 
                WARRANTIES OF TITLE, NON-INFRINGEMENT, OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE,
                OTHER THAN THOSE WARRANTIES WHICH ARE INCAPABLE OF EXCLUSION UNDER LAW.</p>
                <p>CREDIT SCORE MAESTRO DOES NOT GUARANTEE THE ACCURACY, COMPLETENESS, OR TIMELINESS OF, OR OTHERWISE ENDORSE ANY INFORMATION
                CONTAINED ON THE WEBSITE, DUE TO THE NUMBER OF SOURCES FROM WHICH CONTENT DISTRIBUTED BY CREDIT SCORE MAESTRO IS OBTAINED, THERE
                MAY BE DELAYS, OMISSIONS OR INACCURACIES IN SUCH CONTENT. WE DO NOT REPRESENT OR ENDORSE THE ACCURACY OR RELABILITY OF
                ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED, UPLOADED OR DISTRIBUTED THROUGH THE SERVICE
                BY CREDITSCOREMAESTRO, OUR PARTNERS OR ANY USER OF THE SERVICE OR ANY OTHER PERSON OR ENTITY. YOU ACKNOWLEDGE THAT ANY RELIANCE
                UPON ANY SUCH OPINION, MEMBER PROFILE, ADVICE, STATEMENT OR INFORMATION SHALL BE AT YOUR SOLE RISK. YOUR CONTINUED USE OF 
                THE SERVICE NOW, OR FOLLOWING THE POSTING OF NOTICE OF ANY CHANGES IN THIS AGREEMENT, WILL CONSTITUTE A BINDING ACCEPTANCE
                BY YOU OF THIS AGREEMENT, OR ANY SUBSEQUENT MODIFICATIONS.</p>
                <p>YOU HEREBY ACKNOWLEDGE AND AGREE THAT UNDER NO CIRCUMSTANCES WILL CREDIT SCORE MAESTRO, ITS OFFICERS, SHAREHOLDERS, DIRECTORS, 
                EMPLOYEES, AGENTS, AND THIRD PARTY CONTENT PROVIDERS OR LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOSS 
                WHATSOEVER CAUSED BY YOUR USE OR RELIANCE ON INFORMATION OBTAINED THROUGH THE RELIANCE ON INFORMATION OBTAINED THROUGH THE
                CONTENT DISTRIBUTED BY CREDIT SCORE MAESTRO AS WELL AS ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES 
                OR INJURY ARISING OUT OF THE USE OR INABILITY TO USE THE SERVICE OR OUT OF THE BREACH OF ANY WARRANTY, OR CAUSED BY ANY 
                FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS,
                COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO AND/OR ALTERATION OF THE WEBSITE, NO MATTER WHETHER
                SUCH CLAIMS ARE BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, IN WHICH CASE THE PROVISIONS OF THIS SECTION MAY
                NOT APPLY TO YOU.</p>
                <p>NOTWITHSTANDING THE FOREGOING, IN NO EVENT SHALL OUR LIABILITY EXCEED THE SUM OF FIFTY U.S. DOLLARS ($50.00).</p>
                <p>EACH PARTY AGREES THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR
                CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH PARTIES AGREE OTHERWISE, EITHER
                PARTY MAY ONLY SEEK AND/OR ACCEPT AN AWARD OF RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND/OR DECLARATORY RELIEF) IN FAVOR 
                OF THE INDIVIDUAL SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE
                RELIEF NECESSITATAED BY THAT PARTY'S INDIVIDUAL CLAIM(S).</p>
                <p>YOU ALSO AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING FROM OR RELATED 
                TO THE USE OF THE SERVICES MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE AROSE OR BE FOREVER BARRED. THE FAILURE
                OF EITHER PARTY TO EXERCISE, IN ANY RESPECT, ANY RIGHT PROVIDED FOR HEREIN SHALL NOT BE DEEMED A WAIVER OF ANY FURTHER RIGHTS
                HEREUNDER.</p>
                <h3>INDEMNITY</h3>  
                <p>You hereby agree to indemnify, defend, and hold harmless Credit Score Maestro and all officers, directors, owners,agents, information
                providers, affiliates, licensors and licensees (collectively, the "Indemnified Parties") from and against any and all 
                liability and costs, including, without limitation, reasonable attorneys' fees, incurred by the Indemnified Parties in 
                connection with any claim arising out of any breach by You of this Agreement or the foregoing representations, warranties 
                and covenants. You shall cooperate as fully as reasonably required in the defense of any such claim. Credit Score Maestro reserves the
                right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by You.</p> 		
                <h3>MISCELLANEOUS</h3>		
                <p>This Agreement represents the entire Agreement between You and Credit Score Maestro regarding the use of our Services and supersedes 
                any other Agreement or understanding on the subject matter. This Agreement, Your rights and obligations, and all actions contemplated
                by this Agreement shall be governed by the laws of the State of Delaware, U.S.A.</p>
                <p>If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be seperated,limited, or eliminated from 
                and to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and be enforceable. You may not 
                assign, transfer or sublicense this Agreement without the prior written consent of Credit Score Maestro. Credit Score Maestro may assign this Agreement in whole or in part. No agency, partnership, joint venture, or employment is created as a result of this Agreement. 
                Headings are for convenience only and have no legal or contractual effect. All notices under this Agreement shall be in writing 
                and shall be deemed to have been duly given when receipt is electronically confirmed, if transmitted by facsimile or email or 
                upon receipt, if sent by certified or registered mail, return receipt requested.</p> 		
                <h3>CREDIT SCORE MAESTRO PRIVACY POLICY</h3>
                <p>This Privacy Policy (Policy) describes the practices of CreditScoreMaestro.com and any subsidiaries, divisions, and affiliates.
                (We, Our, Us) with respect to Our collection, use, storage, and disclosure of personal information provided to Us from users
                of Our Terms of Agreement, which is incorporated by reference.</p>
                <h3>INTRODUCTION</h3>	
                <p>We respect and uphold individual rights to privacy and the protection of personal information. We understand the importance
                of protecting Your personal information, and it is part of Our mission to protect Your personal information to assure Your 
                experience is safe and secure. This policy was developed to explain Our practices for the collection, use, and disclosure of
                Your personal information.</p>
                <p>For purpose of this Policy, "personal information" means information about an identifiable individual, including, but not 
                limited to, an individual's height, health, birth date, name, home address, telephone number, sex, marital status, income, 
                and financial information. We will only collect, use, or disclose personal information in accordance with this Policy or in
                accord with applicable laws governing the collection, use, or disclosure of Your personal information by Us.</p>
                <p>We have appointed a Privacy Officer to assure compliance with this Policy. Contact the Privacy Officer
                at customerservice@creditscoremaestro.com</p>
                <h3>COLLECTION AND USE OF PERSONAL INFORMATION</h3>
                <p>We collect two types of information through Our Website: personal information and non-personal information, which depends 
                upon Your interaction with Us.</p>  
                <h3>PERSONAL INFORMATION</h3>
                <p>We may collect personal information as follows:</p>	
                <ul className="termnum">
                        <li>1. Your contact information, such as Your name and email address;</li>
                        <li>2. Your personal preferences, such as Your language preferences and marketing consent;</li>
                        <li>3. Your profile information, such as You upload other information in Your personal profile, such as age,
                        interests, martial status, sex, occupation, etc.</li>
                </ul>
                <h3>NON-PERSONAL INFORMATION</h3>
                <p>Non-personal information does not identify You as an individual. Examples are:</p>
                <ul className="termnum">
                        <li>1. When You visit Our Website, We may collect the type of browser You are using or the referring URL; or</li>
                        <li>2. Collection and summarization of customer information in the aggregate format for statistical and research
                        purposes. We may, in those cases, summarize data to determine how many male or female users are between certain
                        age brackets.</li>
                </ul>
                <p>You may elect to not provide Us with personal information, which may render Us unable to provide requested Services. 
                For example, without You providing Your email address, We are unable to verify Your account and send necessary 
                notifications.</p>
                <h3>USE OF PERSONAL INFORMATION</h3> 
                <p>We may use Your personal information for numerous business purposes, such as:</p>
                <ul className="termnum">
                        <li>1. Conduct surveys to improve Our Services;</li>
                        <li>2. Provide You with customer service in responding to Your questions or complaints;</li>
                        <li>3. Inform You of Our Service changes or new Services We may offer in the future;</li>
                        <li>4. Conduct appraisals and improvements to the Website and its Services or our marketing endeavors;</li>
                        <li>5. Detect and protect against fraud and error;</li>
                        <li>6. Track and analyze Your preferences to achieve greater understanding of Your Service needs and eligibility;
                        and</li>
                        <li>7. For other purposes set forth in this Policy.</li>
                </ul>
                <p>We will not collect, sell, or distribute Your personal information for any purpose unrelated to Our business purposes
                in furtherance of the Website without Your express written consent, except as required by law or as authorized by 
                Applicable Privacy Laws:</p>
                <h3>YOUR CONSENT</h3>		
                <p>You consent to the use of Your personal information as identified in this Policy or as may be identified at the time 
                of collection when You provide that information to Us. Your consent may be express or implied.</p>
                <p>At times, We will seek Your express consent to collect, use, or disclose Your personal information. We may, for example,
                ask You to check a box to indicate Your consent to receive marketing communications</p>
                <p>At other times, You may provide implied consent to allow Us to collect, use, or disclose Your personal information when
                We can reasonably conclude that You have given consent by an action taken by You or in an action You decided not to take.
                As a general rule, this occurs where the purpose for the use of Your personal information is apparent to You, You provide implied consent to publish that information 
                for other users to view.</p>
                <h3>WITHDRAWING CONSENT</h3>	
                <p>At any time, You may notify Us of Your desire to withdraw Your consent to Our collection, use, and disclosure of Your 
                personal information. Your notice will be accommodated subject to legal and contractual restrictions.</p> 
                <h3>DISCLOSURE AND SHARING OF YOUR INFORMATION</h3>
                <p>We do not sell or license Your personal information to any party. We may, however, share some of Your personal information
                in the normal course of business with Our affiliates and with third parties acting on Our behalf or as permitted by Applicable
                Privacy Laws.</p>
                <h3>INFORMATION WE MAY COLLECT DIRECTLY</h3>
                <p>Below is information We may collect directly from You and how We use it.</p>
                <p>Follow-Ups and Surveys- at times, We may send a letter thanking You for Your use of the Service, or We may contact You directly
                to assure Your satisfaction with the Services.</p>
                <p>To conduct occasional surveys and other customer research, We may use contact information We collect online. Such surveys are 
                entirely voluntary.</p>
                <h3>AUTOMATIC INFORMATION COLLECTION</h3>
                <p>We collect some information automatically.</p>
                <p>When Our Website is accessed, We may collect the Internet Protocol (IP) address of Your computer, the IP address of Your
                computer, the IP address of Your internet provider, the date and time You access Our Website, the Internet address of the 
                Website that You used to directly link to Our Website, the operating system You are using, the Internet browser You are Using, 
                the Website sections You visit, the Website pages read and images viewed, and the content You download from the Website.</p>  
                <h3>COOKIES</h3>
                <p>The Website uses "cookies," a technology that installs a small amount of information on a Website user's computer
                to permit the Website to recognize future visits using that computer. Cookies enhance the convenience and use of the 
                Website. For example, the information provided through cookies is used to recognize You as a previous user of the Website,
                to offer personalized Web page content and information for Your use, and to otherwise facilitate Your Website experience.
                You may choose to decline cookies if Your browser permits, but doing so may affect Your use of the Website and Your ability 
                to access certain features of the Website or engage in transactions through the Website.</p>
                <h3>WEBSITE TRACKING INFORMATION</h3>
                <p>We may use web beacons or pixel tags to compile tracking information reports regarding Website user demographics, Website 
                traffic patterns, and Website purchases. We may also utilize Google Analytics and its User ID function in the collection, 
                compilation and analysis of this tracking information, which allows us to track Your use of our Website across multiple 
                devices. We may then provide these reports to advertisers and others. None of the tracking information in these reports can 
                be connected to the identities or other personal information of individual users. For Our own research purposes, We may link 
                tracking information with personal information voluntarily provided by Website users. Once such a link is made, all of the 
                linked information is treated as personal information and will be used and disclosed only in accordance with this Policy.</p>
                <h3>LINKS TO OTHER WEBSITES</h3>
                <p>Our Website may contain links to other Websites or Internet resources which are provided solely for Your convenience and information.
                When You click on one of those links You are contacting another Website or Internet resource. We have no responsibility or liability 
                for, or control over, those other Websites or Internet resources or their collection, use, and disclosure of Your personal 
                information. We encourage You to read the privacy policies of those other Websites to learn how they collect and use Your 
                personal information.</p>
                <h3>SUPPLEMENTING INFORMATION</h3>
                <p>At times, We may supplement information You provide Us with information from other sources to, for instance, validate Your 
                address or the other information You provide Us. This assists Us in maintaining accurate information and to assure We provide 
                You better service.</p>
                <h3>SERVICE PROVIDERS</h3>
                <p>We may use third party service providers, such as web hosting providers, data management providers, and/or payment processors to 
                manage one or more aspects of our business operations, including the processing or handling of personal information. We use 
                contractual or other appropriate means when We do use such service providers to ensure that Your personal information is used
                in a manner that is consistent with this Policy.</p>
                <p>Providing increased availability of Our Website may result in using service providers outside the United States, as a result, 
                that information may be accessible to law enforcement and regulatory authorities in accord with the host country.</p>
                <h3>SHARING YOUR INFORMATION</h3> 
                <p>We may share personal information collected on Our Website with and amongst Our corporate subsidiaries, affiliates, or 
                divisions for internal business purposes in accord with this Policy.</p>
                <p>We may be required or permitted by law to disclose Your personal information. For instance, a court of law or other 
                person or entity with jurisdiction may compel Us to provide that information.</p>
                <h3>SECURITY OF YOUR INFORMATION</h3>
                <p>It is Our highest priority to assure Your personal information is safe and secure. To protect that information, We 
                maintain appropriate safeguards and current security standards to protect Your personal information, whether captured on 
                paper or captured electronically, against unauthorized access, disclosure, or misuse.</p>
                <p>We store electronic records in secure, limited-access services; We employ technolgical tools like firewalls and passwords; 
                We ensure Our employees are trained on the importance of maintaining the security and confidentiality of personal
                information. Reasonably secure methods are used whenever We destroy personal information. No system, however, can be completely secure.</p>
                <p>While We take steps to secure Your information, We cannot and do not promise, and You should not expect, that Your 
                personal information will always remain secure.</p>
                <h3>ADVERTISING CAMPAIGNS</h3>
                <p>To supplement Our Website, We rely on advertisers who wish to serve advertisements ("Campaigns")to some or all of Our users  
                to generate revenue. We never share Your personal information with Our advertisers of their Campaigns. However, We will use 
                elements of Your personal information to create a list of users that advertisers may want to direct their Campaigns to.
                Advertisers may decide to service advertisements to all of our users ("General Campaigns"), or to send messages to specific
                segments of our user base ("Direct Campaigns") based on particular personal characteristics (for example, age, sex or 
                geographic location). In the case of Direct Campaigns, We may use different elements of Your personal preferences to develop 
                and display advertising content tailored to Your preferences.</p>
                <h3>MATCHING</h3>
                <p>At times, We will use some elements of Your personal information to create and provide You a list of possible matches 
                from among Our other users.</p>
                <h3>CUSTOMER SUPPORT CENTER</h3>
                <p>To address Your questions, concerns or complaints, contact us at customerservice@CreditScoreMaestro.com. 
                We may require personal information be provided to verify Your identity and assist Us in responding to Your inquiry.</p>
                <h3>REQUEST FOR CORRECTION OF AND ACCESS TO PERSONAL INFORMATION</h3>
                <p>Applicable Privacy Laws allow any individual the right to access and/or request the correction of errors or omissions
                in his or her personal information that is in Our custody or under Our control. Our Privacy Officer will assist the individual 
                with the access request, to include:</p>
                <ul className="termnum">
                        <li>1. Identification of personal information under Our custody and control; and</li>
                        <li>2. Information about how personal information in Our custody or under Our control has been or may be used by Us.</li>
                </ul>
                <p>Requests will be responded to within the time frame required by applicable Privacy Laws; We will make every effort to provide 
                accurate and complete responses. We will promptly send corrected personal information to any organization We provided with that
                information.</p>
                <p>Exceptional circumstances may prevent Us from accessing certain personal information. Security purposes makes some personal 
                information inaccessible and unamenable by the Privacy Officer. In such cases, We will, within 30 days, notify in writing the 
                person making the request.</p>
                <h3>REMOVAL OF YOUR INFORMATION</h3>
                <p>Your information is kept by Us only as long as it is needed for legitimate business purposes and to meet legal requirements.Personal information needed to make a decision that directly affects an individual will be kept for at least one year after such decision. Our retention parameters meet these standards.</p>
                <h3>CONCERNS OR QUESTIONS REGARDING OUR COMPLIANCE</h3>
                <p>Questions or concerns regarding Our compliance may be directed to the Privacy Officer.</p>
                <h3>DIGITAL MILLENNIUM COPYRIGHT ACT NOTICE</h3>
                <p>If You believe Your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible on Our Website, please notify Our copyright agent, as set forth in the Digital Millennium Copyright Act of 1998 (DMCA). For Your DMCA complaint to be valid, You must provide in writing the following:</p>
                <ul className="termnum">
                        <li>1. An electronic or physical signature of a person authorized to act on behalf of the copyright owner;</li>
                        <li>2. Identification of the copyrighted work that You claim is being infringed;</li>
                        <li>3. Identification of the material that is claimed to be infringing and where it is located on the Site;</li>
                        <li>4. Information reasonably sufficient to permit CreditScoreMaestro to contact You, such as Your address, telephone
                        number, and e-mail address;</li>
                        <li>5. A statement that You have a good faith belief that use of the material in the manner complained of is not 
                        authorized by the copyright owner, its agent, or law; and</li>
                        <li>6. A statement, made under penalty of perjury, that the above information is accurate, and that You are the 
                        copyright owner or are authorized to act on behalf of the owner.</li>
                </ul>
                </div>
            </UniHeader>
        </Layout>
    );
}

export default TermsofUse;